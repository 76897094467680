@use '@angular/material' as mat;
@use 'colors';
@use 'palettes';

// Include the core styles once
@include mat.core();

// Define a default typography config
$typography-config: mat.define-typography-config(
    $body-1: mat.define-typography-level(14px, 1.5),
);

$_default-warn-color: map-get($map: colors.$theme-color-defaults, $key: 'warn');
$_warn-palette: palettes.generate($_default-warn-color);
$_default-warn-palette : mat.define-palette($_warn-palette);
$_default-primary-palette: mat.define-palette(mat.$grey-palette, 900);

@mixin _createTheme($color) {
    $accent-color: map-get($map: colors.$accent-colors, $key: $color);
    $accent-palette-map: palettes.generate($accent-color);
    $accent-palette: mat.define-palette($accent-palette-map);

    // Create the theme object with color, typography, and density
    $theme: mat.define-light-theme((
        color: (
            primary: $_default-primary-palette,
            accent: $accent-palette,
            warn: $_default-warn-palette,
        ),
        typography: $typography-config,
        density: -1,
    ));

    .theme-#{$color} {
        @include mat.all-component-themes($theme);

        // Increase density specifically for form field
        @include mat.form-field-density(-1);

        /* CSS Variables scoped to the theme class */
        --accent-color: #{$accent-color};
        --accent-color-muted: #{colors.createMutedColor($accent-color)};
        --accent-color-medium: #{rgba($accent-color, .75)};
    }
}

@mixin createThemes() {
    @each $key in map-keys($map: colors.$accent-colors) {
        @include _createTheme($key);
    }
}
