/*
   This file should only be used for global styles which need to be applied to top-level elements
   or broadly across the application. Use the component's style sheet to define component-specific
   styles.
*/
@use 'styles/theme/theme';
@use 'styles/shared/colors';
@use 'styles/shared/dialogs';
@import 'styles/material-overrides';
@import 'styles/material-fixes';
@import 'styles/print-styles';

@include theme.createThemes();


@font-face {
    font-family: 'ean13';
    src: url('assets/upc-ean-l.ttf') format('truetype');
}

html,
body {
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

body {
    min-height: 100%;
    background-color: colors.$page-background;
    font-size: 14px;
}

/* Simple dialog should be used for most dialogs. This fixes the dialog size to the recommended
   width in the Material Design guidelines */
.simple-dialog {
    width: dialogs.$desktop-simple-dialog-width;
}

@media (max-width: dialogs.$desktop-simple-dialog-max-width) {
    .simple-dialog {
        width: 95%;
    }
}

/* This should be used for larger dialogs like wizards or those with a lot of information. These
   dialogs will span the majority of the screen regardless of screen size */
.full-width-dialog {
    width: 95%;
}
