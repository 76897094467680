#print-outlet {
    display: none;
}

@media print {
    @page {
        size: landscape;
    }

    html,
    body {
        page-break-after: avoid;
        page-break-before: avoid;
    }

    body.portrait {
        width: 8.5in;
        margin: 0;
    }

    .printable #print-outlet {
        display: block;
    }

    .printable hq-root {
        display: none;
    }

    /* Material tooltips, dialogs, and snackbars appear under this element */
    .printable .cdk-overlay-container {
        display: none;
    }
}
