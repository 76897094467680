/* This contains workarounds for bugs currently in Angular Material while we await fixes */

@use 'apps/hq/src/styles/shared/progress-bars';
@use 'apps/hq/src/styles/shared/toolbars';

/* Alternative to using "sticky: true" on the matHeaderRowDef. It adds a top: 0 CSS rule
   that can only be overridden with an !important directive. Rather than use a heavy-handed
   solution, we can apply the needed styles with a class until that issue is addressed.
   https://github.com/angular/components/issues/14463
*/
.sticky-header {
    position: sticky;
    z-index: 100;
    top: toolbars.$toolbar-height-default + progress-bars.$progress-bar-height;
}

@media (orientation: portrait) and (max-width: 599px),
(orientation: landscape) and (max-width: 599px) {
    .sticky-header {
        position: sticky;
        z-index: 100;
        top: toolbars.$toolbar-height-sub-600 + progress-bars.$progress-bar-height;
    }
}
