/* This file should contain styles which are needed to override Angular Material defaults as they
   cannot be applied from the component's style sheet */

@use 'apps/hq/src/styles/shared/colors';

/* This class is intended for the panel overlay of MatSelect components. Some components can apply
   a CSS class to it. Because the panels are appended to the <body> tag, we have to use the global
   style sheet to target it as component styles are outside its scope. */
.selection-panel .mat-mdc-option {
    font-size: 14px;
}

/* Overrides the text color of a multi-select MatSelect */
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-select-panel .mat-mdc-option.mat-selected:not(.mat-option-disabled) .mat-option-text {
    color: colors.$black-87-opacity;
}

.base-dialog.warning .mat-mdc-dialog-title {
    color: colors.$theme-warn;
}

/* Padding is handled by the component itself */
.filter-menu .mat-mdc-menu-content,
.filter-menu .mat-menu-content:not(:empty),
.filter-menu .mat-expansion-panel-body,
.pending-products .mat-expansion-panel-body,
.dense-filter-menu .mat-menu-content,
.dense-filter-menu .mat-menu-content:not(:empty) {
    padding: 0;
}

.filter-menu.mat-mdc-menu-panel {
    min-width: 280px;
    // The Angular Material default makes the menu extend past the bottom of the screen
    max-height: calc(100vh - 100px);
}

.dense-filter-menu.mat-mdc-menu-panel {
    // padding(16px x 2) + buttonsAndBordersPerRow((44px + 1px) * 9) + extraBorder(1px)
    min-width: 438px;
}

.mat-mdc-row:hover,
.mat-mdc-row:hover .mat-mdc-cell {
    background-color: colors.$row-hover;
}

/* Change the icon color for steppers */
[class^="theme-"] .stepper,
[class^="theme-"] .stepper .completed-step {

    .mat-step-icon,
    .mat-step-icon.mat-step-icon-selected {
        background-color: colors.$theme-medium-accent;
    }

    .mat-step-icon.mat-step-icon-selected {
        background-color: colors.$theme-accent;
    }
}

/*
Aligns header cells with a before arrow to match header cells with after arrows
Fits a particular edge case in LocalVendorProductsComponent
*/
.arrow-before.mat-mdc-header-cell {
    overflow: visible;

    .mat-sort-header-position-before {
        justify-content: flex-end;
    }

    .mat-sort-header-arrow {
        margin-left: -18px;
    }
}

.orders,
.line-items,
.turnover-products,
.invoice-products {
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    .mat-list-item-content {
        display: flex;
        flex: 1;
        justify-content: space-between;
        min-width: 0;
    }
}

.received-line-items.product.mat-mdc-list-item {
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    .mat-list-item-content {
        display: grid;
        grid-template-columns: minmax(275px, 3fr) repeat(3, 90px) 125px 90px;

        .row {
            grid-column: 1 / 7;
        }

        @media all and (max-width: 875px) {
            grid-template-columns: 2fr repeat(3, 90px) 125px 90px;
        }
    }
}

.broker-ordering .mat-mdc-tab-body,
.vendor-ordering .mat-mdc-tab-body,
.retailer-ordering .mat-mdc-tab-body {
    background-color: colors.$light-gray;
}

.preview-container .mat-expansion-panel-body {
    padding: 8px 16px;
}

/* overwriting Google places autocomplete styles with base Material styles for an autocomplete item */
.pac-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 48px;
    height: 48px;
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    box-sizing: border-box;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    border-top: none;
}

.theme-green,
.theme-blue,
.theme-teal {
    .mat-toolbar.mat-primary {
        background: colors.$black !important;
    }
}

// overwrites the default style for slide toggles
.toggle {
    .mdc-form-field {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .mdc-form-field--align-end > label {
        margin-left: 0;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
hq-deadline-dialog .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0;
    line-height: normal;
}

hq-retailer-line-item {
    .actions-container .mdc-form-field > label {
        padding-left: 0;
    }
}

hq-catalog-line-item {
    .mdc-form-field > label {
        padding-left: 0;
    }
}

hq-saved-item {
    hq-primary-product-details .card {
        box-shadow: none !important;
    }
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
    margin-right: 16px;
}

hq-broker-line-item,
hq-retailer-invoice-line-items,
hq-received-order-line-item {
    .mdc-list-item__content {
        padding: 0 16px;
    }
}

hq-broker-line-item .mdc-list-item__primary-text {
    line-height: 18px;
}

hq-internal-line-items {
    .mdc-list-item__primary-text {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        position: relative;
        height: inherit;
    }
}

hq-vendor-order,
hq-broker-orders,
hq-broker-line-item,
hq-turnover-orders,
hq-broker-store-contacts,
hq-internal-order,
hq-retailer-acknowledgement,
hq-retailer-invoice-line-items,
hq-retailer-line-item, {
    .mdc-list-item__primary-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        position: relative;
        height: inherit;
    }
}

hq-broker-line-item .mdc-list-item__primary-text {
    align-items: initial;
}

.mat-mdc-list-base {
    --mdc-list-list-item-label-text-line-height: 20px;
    --mdc-list-list-item-label-text-size: 14px;
    --mdc-list-list-item-supporting-text-line-height: 18px;
    --mdc-list-list-item-supporting-text-size: 14px;
}


.theme-teal,
.theme-green {
    .mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
        --mat-mdc-fab-color: #fff !important;
    }

    .mat-mdc-unelevated-button.mat-accent {
        --mdc-filled-button-label-text-color: #fff !important;
    }

    .mat-mdc-raised-button.mat-accent {
        --mdc-protected-button-label-text-color: #fff !important;;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-suffix .mat-mdc-icon-button.mat-mdc-button-base {
    width: 14px !important;
    height: 14px !important;
    padding: 0 !important;
    line-height: 0;

    .mat-mdc-button-touch-target {
        width: 14px !important;
        height: 14px !important;
        top: 100%;
    }

    .mat-icon {
        font-size: 14px;
        height: 14px;
        width: 14px;
        overflow: visible;
    }
}

.open-order {
    font-size: 14px !important;
}

.search-option {
    .mdc-list-item__primary-text {
        width: 100%;
    }
}
