@use 'apps/hq/src/styles/theme/colors';

$white: #ffffff;
$black: #1F282C;
$black-87-opacity: rgba($black, .87);
$black-75-opacity: rgba($black, .75);
$black-60-opacity: rgba($black, .60);
$black-54-opacity: rgba($black, .54);
$black-30-opacity: rgba($black, .30);
$black-20-opacity: rgba($black, .20);
$black-10-opacity: rgba($black, .10);
$black-04-opacity: rgba($black, .04);
$black-02-opacity: rgba($black, .02);

$light-gray: #e0e0e0;
$dark-gray: #757575;

$sale-green: rgb(59, 188, 122);
$light-green: #54c494;
$success-green: #1f8a5c;

$gray-blue: #557085;
$light-blue: #54a2d2;
$icon-blue: cornflowerblue;

$icon-orange: orange;
$future-sale-brown: rgb(250, 235, 204);

$text-default: $black-87-opacity;
$muted-text: $black-60-opacity;
$section-heading: $black-60-opacity;
$text-shadow: $black-02-opacity;
$readonly-label: $black-54-opacity;

$theme-accent: colors.$hq-accent-color;
$theme-muted-accent: colors.$hq-muted-accent-color;
$theme-medium-accent: colors.$hq-medium-accent-color;
$theme-warn: colors.$hq-warn-color;

$product-status-icon-blue: $icon-blue;
$product-status-icon-orange: $icon-orange;
$product-status-icon-red: colors.$hq-warn-color;
$product-status-icon-green: $sale-green;

$current-special: $sale-green;
$future-special: $future-sale-brown;
$expired-special: colors.$hq-warn-color;

$divider: $black-10-opacity;

$status-card-blue: $light-blue;
$status-card-green: $light-green;
$status-card-gray-blue: $gray-blue;
$status-card-light-blue: $icon-blue;
$status-card-red: colors.$hq-warn-color;
$status-card-accent: colors.$hq-accent-color;
$status-card-light-green: $light-green;
$status-card-dark-green: $success-green;
$status-card-yellow: #f4b400;
$missing-thumbnail: $black-30-opacity;
$default-icon: $black-54-opacity;
$disabled-icon: $black-30-opacity;
$empty-state-icon: $black-30-opacity;

$box-shadow: $black-20-opacity;
$row-hover: $light-gray;
$page-background: $light-gray;

$border-background: #f8f8f8;
$order-header-background: #f5f5f5;
